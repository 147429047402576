.HeaderSteps .steps .path {
  width: 20%;
  height: 3px;
  background-color: rgb(92 96 123 / 30%);
  border-radius: 3px;
}
.HeaderSteps .step .circle {
  width: 50px;
  height: 50px;
  background-color: rgb(92 96 123 / 30%);
  border-radius: 50%;
  color: #5c607b;
  font-size: 24px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .HeaderSteps .step .circle {
    width: 40px;
    height: 40px;
  }
}
.HeaderSteps .step span {
  color: #5c607b;
}
.HeaderSteps .one .circle {
  background-color: var(--main-color);
  color: white;
}
.HeaderSteps .done-circle {
  background-color: var(--main-color) !important;
  color: white !important;
}