.ArticleComponent {
  transition: 0.5s;
}
.ArticleComponent::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: var(--main-color);
  opacity: 0.05;
  z-index: -1;
  transition: 1s;
}
.ArticleComponent::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main-color);
  opacity: 0.05;
  z-index: -1;
  transition: 1s;
}

.ArticleComponent:hover::after {
  height: 100%;
}
.ArticleComponent:hover::before {
  height: 100%;
}
.ArticleComponent:hover {
  transform: translateY(-15px);
}

.ArticleComponent a{
    transition: 1s;
}

.ArticleComponent a:hover{
    border: none;
    background-color: var(--main-color);
    color: white;
}

.Articles .text {
  max-width: 603px;
}
.ArticleComponent a {
  width: 166px;
  height: 48px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}
