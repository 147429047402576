.TeamMembers select:focus-visible{
    outline:var(--main-color) 2px solid !important ;
}
.TeamMembers select{
    padding: 8px 8px 8px 0;
}
/* ----------------- */
.TeamMembers select option:hover{
    background-color: var(--main-color);
    color: white;
}
.TeamMembers select {

    /* styling */
    background-color: white;
    border: thin solid blue;
    border-radius: 10px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 16px 40px;
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  
  /* arrows */
  
  .TeamMembers select.classic {
    background-image:
      linear-gradient(45deg, transparent 50%, #018543 50%),
      linear-gradient(135deg, #018543 50%, transparent 50%),
      linear-gradient(to right, #01854373, #01854373);
    background-position:
      calc(100% - 15px) calc(1.2em + 8px),
      calc(100% - 10px) calc(1.2em + 8px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2em 3.5em;
    background-repeat: no-repeat;
  }
  
  .TeamMembers select.classic:focus {
    background-image:
      linear-gradient(-45deg, white 50%, transparent 50%),
      linear-gradient(-135deg, transparent 50%, white 50%),
      linear-gradient(to right, var(--main-color),  var(--main-color));
      background-position:
      calc(100% - 15px) calc(1.2em + 8px),
      calc(100% - 10px) calc(1.2em + 8px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2em 3.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }