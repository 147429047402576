.RadioBtn {
    background: #F7F7F7;
}
.RadioBtn .content {
  min-height: calc(100vh - 96px);
  background: #ffffff;
  border-radius: 29px;
}
.RadioBtn .card {
  height: 206px;
  width: 100%;
  position: relative;
  border: none;
}
.RadioBtn input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  border: 0.7px solid #ececec;
}
.RadioBtn input[type="radio"]:before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  background-color: #f9fafd;
  border: 1px solid #e2e6f3;
  border-radius: 50%;
  top: 19px;
  right: 19px;
}
.RadioBtn input[type="radio"]:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: transparent;
  border-radius: 50%;
  top: 23px;
  right: 23px;
}
.RadioBtn label {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 0 20px;
  text-align: center;
}
.RadioBtn input[type="radio"]:hover {
  transform: scale(1.05);
  box-shadow: 7px 7px 15px rgba(2, 28, 53, 0.08);
}
.RadioBtn input[type="radio"]:checked {
    border: 1.5px solid var(--main-color) ;
}
.RadioBtn input[type="radio"]:checked:after {
  background-color: var(--main-color);
}
.RadioBtn .Btn {
  width: 60%;
}
@media (max-width: 767px) {
  .RadioBtn .Btn {
    width: 100%;
  }
}
