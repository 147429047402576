.CvPage .landing {
  background-image: url("./Assets/Landing.png"),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 343px;
}
.CvPage .text {
  max-width: 492px;
}
.CvPage .content {
  background-color: #f7f7f7;
}
.CvPage .content .cover-cv {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
  box-shadow: 0 0 20px 8px #0000002b;
}
