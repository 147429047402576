.EducationProgra .row {
  row-gap: 24px;
}
.EducationProgra .image-circle {
  height: 463px;
  width: 458px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .EducationProgra .image-circle {
    width: 350px;
    height: 350px;
  }
}
/* Images */
.EducationProgra .image-circle img {
  position: absolute;
}
.EducationProgra .image-circle img:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.EducationProgra .image-circle img:nth-child(2) {
  top: 30%;
  left: 0;
}
.EducationProgra .image-circle img:nth-child(5) {
  top: 70%;
  left: 7%;
}
.EducationProgra .image-circle img:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.EducationProgra .image-circle img:nth-child(6) {
  top: 55%;
  right: 3%;
}
.EducationProgra .image-circle img:nth-child(4) {
  top: 20%;
  right: 5%;
}
/* Images */
/* Counter */
.EducationProgra .counter {
  width: 210px;
  height: 117px;
  background: #ffffff;
  box-shadow: 0px 17px 41px rgba(135, 135, 135, 0.25);
  border-radius: 26px;
  position: relative;
}
.EducationProgra .counter span{
  color: var(--main-color);
}
.EducationProgra .counter .icon{
  position: absolute;
}
.EducationProgra .counter .icon:first-child{
  top: 0;
  left: 0;
}
.EducationProgra .counter .icon:nth-child(2){
  bottom: 0;
  right: 0;
}
/* Counter */
