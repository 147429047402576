.ProfileEmployeImage {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("./Vector.png"),
    linear-gradient(rgb(237 237 241 / 100%), rgb(237 237 241 / 100%));
  background-position: bottom;
  background-repeat: no-repeat;
}
.ProfileEmployeImage .icon {
  position: absolute;
  bottom: -2px;
  right: 8px;
  transform: translateX(50%);
}

.ProfileEmployeImage .image-profile {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 50%;
}
