.SearchInEmployes .search{
    background-color: #F7F7F7;
}
.SearchInEmployes input {
  outline: none;
  border: none;
  background-color: transparent;
}

.SearchInEmployes .note span{
    min-width: 25px;
    max-width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--main-color);
    color: white;
}