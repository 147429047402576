.NewPass {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.57) 0%,
      rgba(0, 0, 0, 0.57) 100%
    ),
    url("../Assets/LoginCover.jpeg");
  background-size: cover;
  background-position: center;
}

.NewPass .content {
  min-height: calc(100vh - 96px);
  border-radius: 29px;
  background-color: white;
  max-width: 811px;
}

.NewPass .content p {
  max-width: 380px;
}

.NewPass .content form button {
  border: none;
  background-color: var(--main-color);
  padding: 14px;
  border-radius: 8px;
  color: white;
}

.NewPass form input {
    border: none;
    width: 100%;
    outline: none;
  }
  